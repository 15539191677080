<template>
  <div>
    <div class="progress" v-if="file.active">
      <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
    </div>
    <file-upload
        style="display: block; text-align: left"
        ref="upload"
        v-model="files"
        :data="data"
        :name="inputName"
        :headers="headers"
        :post-action="url"
        @input-file="inputFile"
        @input-filter="inputFilter"
      >
        <div class="file-upload-in">{{ inputLabel }}</div>
    </file-upload>
  </div>
</template>
<script>
import Vue from 'vue'
import VueUploadComponent from 'vue-upload-component'
import VueCookie from 'vue-cookie'
// Tell Vue to use the plugin
Vue.use(VueCookie);
export default {
  components: {
    FileUpload: VueUploadComponent
  },
  props: {
    reservationId: {
      type: Number,
      default: null
    },
    booking: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    inputName: {
      type: String,
      default: 'location[attachment]'
    },
    inputLabel: {
      type: String,
      default: 'Attach file'
    }
  },
  data() {
    return {
      files: [],
      accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: false,
      directory: false,
      drop: false,
      dropDirectory: false,
      addIndex: false,
      thread: 3,
      data: {
        booking: this.booking,
        reservation_id: this.reservationId
      },
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      }
    }
  },
  computed: {
    urlWithParam() {
      return this.url + '?booking=1'
    },
    headers() {
      var csrf = this.$cookie.get('CSRF-TOKEN')
      if (csrf == '') {
        csrf = this.getMeta('csrf-token')
      }
      return {
        'X-CSRF-Token': csrf
      }
    },
    file() {
      if (this.files[0]) {
        return this.files[0]
      }
      return {}
    }
  },
  methods: {
    getMeta(metaName) {
      const metas = document.getElementsByTagName('meta');

      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
          return metas[i].getAttribute('content');
        }
      }
      return '';
    },
 /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr) {
          if (newFile.xhr.status == 201) {
            const attachment = JSON.parse(newFile.xhr.response)
            this.$refs.upload.active = false
            this.$emit('uploaded', attachment)
          }
        }
      }

      // Automatic upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    }
  }
}
</script>
